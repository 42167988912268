import React from 'react';
import { Route, useLocation, Routes, Navigate } from 'react-router-dom';

import ProtectedRoute from './ProtectedRoutes';
import UnAuthOnlyRoutes from './UnAuthOnlyRoutes';
import UnPlayOnlyRoutes from './UnPlayOnlyRoutes';
import PlayProtectedRoute from './PlayProtectedRoutes';

// Pages
import MainMenu from '../pages/MainMenu/MainMenu';
import Instructions from '../pages/Instructions/Instructions';
import Login from '../pages/Login/Login';
import Contact from '../pages/Contact/Contact';
import Questions from '../pages/Questions/Questions';
import TeamUp from '../pages/TeamUp/TeamUp';
import JoinTeam from '../pages/JoinTeam/JoinTeam';
import CreateTeam from '../pages/CreateTeam/CreateTeam';
import LeaderBoard from '../pages/LeaderBoard/LeaderBoard';
import TeamDashboard from '../pages/TeamDashboard/TeamDashboard';
import GameOver from '../pages/GameOver/GameOver';
import SimpleLoader from '../pages/SimpleLoader/SimpleLoader';
import PageNotFound from '../pages/PageNotFound/PageNotFound';
import ErrorPage from '../pages/ErrorPage/ErrorPage';

const AllRoutes = () => {
   const location = useLocation();

   return (
      <Routes location={location} key={location.pathname} initial={false}>
         {/* <Route path="/" exact element={<MainMenu />} /> */}
         {/* <Route path="/instructions" element={<Instructions />} /> */}
         <Route path="/contact" element={<Contact />} />
         {/* <Route path="/error-page" exact element={<ErrorPage />} /> */}
         <Route path="*" exact element={<Navigate to="/game-over" />} />

         {/* <Route element={<UnAuthOnlyRoutes />}> */}
         {/* <Route path="/login" element={<Login />} /> */}
         {/* </Route> */}

         {/* <Route element={<ProtectedRoute />}> */}
         <Route path="/leaderboard" element={<LeaderBoard />} />
         {/* <Route element={<UnPlayOnlyRoutes />}> */}
         {/* <Route path="/team-up" element={<TeamUp />} /> */}
         {/* <Route path="/create-team" element={<CreateTeam />} /> */}
         {/* <Route path="/join-team" element={<JoinTeam />} /> */}
         {/* </Route> */}
         {/* <Route element={<PlayProtectedRoute />}> */}
         {/* <Route path="/team-dashboard" element={<TeamDashboard />} /> */}
         <Route path="/game-over" exact element={<GameOver />} />
         {/* <Route path="/question" element={<Questions />} /> */}
         {/* </Route> */}
         {/* </Route> */}
      </Routes>
   );
};

export default AllRoutes;
