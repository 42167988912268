import React, { useEffect, useState, useContext } from 'react';
import Avatar from 'boring-avatars';

import Loader from '../../components/Loader/Loader';

import { PlayingContext } from '../../contexts/PlayingContext';

import styles from './TeamDashboard.module.css';

function TeamDashboard() {
   const [loading, setLoading] = useState(true);
   const { handleGetTeamDetails, teamDetails } = useContext(PlayingContext);

   useEffect(() => {
      const getTeamDetails = async () => {
         setLoading(true);

         await handleGetTeamDetails();

         setLoading(false);
      };

      getTeamDetails();

      return () => {};
   }, []);

   if (loading) {
      return <Loader />;
   }

   return (
      <>
         <div className={styles.wrapper}>
            <div className={styles.HeadingContainer}>
               <h1 className={styles.Heading}>Team Dashboard</h1>
            </div>

            <div className={styles.layout}>
               <div className={styles.teamName}>
                  <h2>{teamDetails.teamName}</h2>
               </div>

               <div className={styles.teamMembers}>
                  {teamDetails && teamDetails.isSolo ? (
                     <div className={styles.member}>
                        <Avatar size={70} name={teamDetails.member1Name} variant="beam" colors={['#FC370C', '#F0AB3D', '#FCEBB6', '#78C0A8', '#F07818']} />
                        <h3>{teamDetails.member1Name}</h3>
                     </div>
                  ) : (
                     teamDetails && (
                        <>
                           <div className={styles.member}>
                              <Avatar size={70} name={teamDetails.member1Name} variant="beam" colors={['#FC370C', '#F0AB3D', '#FCEBB6', '#78C0A8', '#F07818']} />
                              <h3>{teamDetails.member1Name}</h3>
                           </div>

                           <div className={styles.member}>
                              <Avatar
                                 size={70}
                                 name={teamDetails.member2Name == null ? '?' : teamDetails.member2Name}
                                 variant="beam"
                                 colors={['#FC370C', '#F0AB3D', '#FCEBB6', '#78C0A8', '#F07818']}
                              />
                              <h3>{teamDetails.member2Name == null ? 'Not Joined Yet' : teamDetails.member2Name}</h3>
                           </div>
                        </>
                     )
                  )}
               </div>

               <div className={styles.teamStats}>
                  <div className={styles.teamScore}>
                     Team Score <span>{teamDetails.score}</span>
                  </div>
                  <div className={styles.teamRank}>
                     Rank <span>{teamDetails.teamRank}</span>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}

export default TeamDashboard;
