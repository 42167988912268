import React, { useEffect, useState, useContext } from 'react';

import confetti from 'canvas-confetti';

// import { PlayingContext } from '../../contexts/PlayingContext';

import Loader from '../../components/Loader/Loader';

import Trophy from '../../assets/images/trophy.png';
import styles from './LeaderBoard.module.css';

const LeaderBoard = () => {
   // const { handleGetLeaderboard } = useContext(PlayingContext);

   useEffect(() => {
      // do this for 30 seconds
      var duration = 5 * 1000;
      var end = Date.now() + duration;

      var colors = ['#e23e27', '#ffffff'];

      (function frame() {
         confetti({
            particleCount: 2,
            angle: 60,
            spread: 100,
            origin: { x: 0 },
            colors: colors,
         });
         confetti({
            particleCount: 2,
            angle: 120,
            spread: 100,
            origin: { x: 1 },
            colors: colors,
         });

         if (Date.now() < end) {
            requestAnimationFrame(frame);
         }
      })();
   }, []);

   const [top10Details, setTop10Details] = useState([
      { team: { rank: '1', teamName: 'Natchathirangal' }, score: '280' },
      { team: { rank: '2', teamName: 'Crime seekers' }, score: '280' },
      { team: { rank: '3', teamName: 'RAIDEN SHOGUN' }, score: '280' },
      { team: { rank: '4', teamName: 'HEMS' }, score: '280' },
      { team: { rank: '5', teamName: 'Oreo & Milkshake' }, score: '275' },
      { team: { rank: '6', teamName: 'Wall breakers' }, score: '270' },
      { team: { rank: '7', teamName: 'CODE BLACK' }, score: '250' },
      { team: { rank: '8', teamName: 'Raj' }, score: '170' },
      { team: { rank: '9', teamName: 'SOLO 3' }, score: '165' },
      { team: { rank: '10', teamName: 'Kitty' }, score: '155' },
      { team: { rank: '11', teamName: 'Mystery man' }, score: '155' },
   ]);

   // const [teamDetails, setTeamDetails] = useState({
   //    teamName: '',
   //    member1Name: '',
   //    member2Name: '',
   //    teamScore: 0,
   //    teamRank: 0,
   //    isFirstTen: false,
   // });

   // useEffect(() => {
   //    setLoading(true);

   //    const getLeaderBoard = async () => {
   //       const { top10Details, teamStatus } = await handleGetLeaderboard();

   //       setTop10Details(top10Details);
   //       setTeamDetails(teamStatus);
   //       setLoading(false);
   //    };

   //    getLeaderBoard();

   //    return () => {};
   // }, []);

   // if (loading) {
   //    return <Loader />;
   // }

   return (
      <>
         <div className={`${styles.full_page}`}>
            <div className={`${styles.title}`}>LeaderBoard</div>
            <div>
               <img className={`${styles.image}`} src={Trophy} alt="trophy"></img>
            </div>

            <div className={`${styles.table}`}>
               <div className={`${styles.record} ${styles.tableHead} `}>Rank</div>
               <div className={`${styles.record} ${styles.tableHead}`}>Team Name</div>
               <div className={`${styles.record} ${styles.tableHead}`}>Points</div>
               {top10Details.map((val, i) => {
                  let textColor = { color: 'white' };

                  // if (teamDetails && i === teamDetails.teamRank - 1) {
                  //    textColor = { color: '#ff723c' };
                  // }

                  return (
                     <>
                        <div style={textColor} className={`${styles.record}`}>
                           {i + 1}
                        </div>
                        <div style={textColor} className={`${styles.record}`}>
                           {val.team.teamName}
                        </div>
                        <div style={textColor} className={`${styles.record}`}>
                           {val.score}
                        </div>
                     </>
                  );
               })}

               {/* {teamDetails && teamDetails.isFirstTen ? null : (
                  <>
                     <div style={{ color: '#ff723c' }} className={`${styles.record}`}>
                        {teamDetails && teamDetails.teamRank}
                     </div>
                     <div style={{ color: '#ff723c' }} className={`${styles.record}`}>
                        {teamDetails && teamDetails.teamName}
                     </div>
                     <div style={{ color: '#ff723c' }} className={`${styles.record}`}>
                        {teamDetails && teamDetails.teamScore}
                     </div>
                  </>
               )} */}
            </div>
         </div>
      </>
   );
};

export default LeaderBoard;
