import React, { useEffect } from 'react';

import { BrowserRouter as Router } from 'react-router-dom';

import AllRoutes from './routes';
import Navbar from './components/Navbar/Navbar';
import Particle from './components/Particle';

// contexts
// import { AuthProvider } from './contexts/AuthContext';
// import { QuestionsProvider } from './contexts/QuestionsContext';
// import { PlayingProvider } from './contexts/PlayingContext';

import './fonts.css';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer } from 'react-toastify';
import { showSuccessToastNotification } from './components/ToastNotification';
import EventTimer from './components/Timer/EventTimer';

function App() {
   // showSuccessToastNotification(<p style={{ fontFamily: 'Stylish' }}>Game extended till 17/04/2023 ( Monday ) 4.00 PM IST</p>);

   useEffect(() => {
      const ele = document.getElementById('my-loader');
      if (ele) {
         // fade out
         setTimeout(() => {
            // remove from DOM
            ele.classList.add('available');
         }, 1000);
      }
   }, []);

   return (
      <>
         <Router>
            {/* <AuthProvider> */}
            {/* <PlayingProvider> */}
            {/* <QuestionsProvider> */}
            <ToastContainer />
            <Particle />
            <Navbar />
            <img src={`${process.env.PUBLIC_URL}/images/kurukshetra_logo_theme_sponsor.png`} alt="K! Logo" className="Klogo" />
            {/* <EventTimer /> */}
            <AllRoutes />
            {/* </QuestionsProvider> */}
            {/* </PlayingProvider> */}
            {/* </AuthProvider> */}
         </Router>
      </>
   );
}

export default App;
