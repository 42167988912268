import React, { useContext } from 'react';
import { FaHome, FaPhone, FaSignInAlt, FaList, FaTrophy, FaPlay } from 'react-icons/fa';
import { BsFillPeopleFill } from 'react-icons/bs';
import { MdSpaceDashboard } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { AuthContext } from '../../contexts/AuthContext';
import { PlayingContext } from '../../contexts/PlayingContext';

import styles from './Navbar.module.css';

export default function Navbar() {
   // const { auth, handleKLogout } = useContext(AuthContext);
   // const { isPlaying } = useContext(PlayingContext);

   const toggleNav = () => {
      const checkbox = document.getElementById('checkbox');

      if (checkbox.checked) {
         checkbox.checked = false;
      }
   };

   return (
      <nav className={styles.navBar} role="navigation">
         <div className={styles.menuToggle}>
            <input id="checkbox" type="checkbox" />
            <span></span>
            <span></span>
            <span></span>
            <ul className={styles.menu}>
               <Link to="/" onClick={toggleNav}>
                  <li>
                     <FaHome />
                     &nbsp;&nbsp;Home
                  </li>
               </Link>
               {/* {auth ? (
                  <Link to="/question" onClick={toggleNav}>
                     <li>
                        <FaPlay />
                        &nbsp;&nbsp;Play
                     </li>
                  </Link>
               ) : null} */}

               {/* {auth ? ( */}
               <Link to="/leaderboard" onClick={toggleNav}>
                  <li>
                     <FaTrophy />
                     &nbsp;&nbsp;Leaderboard
                  </li>
               </Link>
               {/* ) : null} */}
               {/* <Link to="/instructions" onClick={toggleNav}>
                  <li>
                     <FaList />
                     &nbsp;&nbsp;Instructions
                  </li>
               </Link> */}
               <Link to="/contact" onClick={toggleNav}>
                  <li>
                     <FaPhone />
                     &nbsp;&nbsp;Contact
                  </li>
               </Link>
               {/* {auth ? null : (
                  <Link to="/login" onClick={toggleNav}>
                     <li>
                        <FaSignInAlt />
                        &nbsp;&nbsp;Login
                     </li>
                  </Link>
               )}
               {isPlaying ? null : (
                  <Link to="/team-up" onClick={toggleNav}>
                     <li>
                        <BsFillPeopleFill />
                        &nbsp;&nbsp;Team
                     </li>
                  </Link>
                )} 
               {isPlaying ? (
                  <Link to="/team-dashboard" onClick={toggleNav}>
                     <li>
                        <MdSpaceDashboard />
                        &nbsp;&nbsp;Dashboard
                     </li>
                  </Link>
               ) : null}
               {auth ? (
                  <li
                     onClick={() => {
                        toggleNav();
                        handleKLogout();
                     }}
                     style={{ cursor: 'pointer' }}
                  >
                     <FaSignInAlt />
                     &nbsp;&nbsp;Logout
                  </li>
               ) : null} */}
            </ul>
         </div>
      </nav>
   );
}
