import React from 'react';
import { useNavigate } from 'react-router-dom';

import { INSTRUCTIONS } from '../../data/Instructions';

import styles from './Instructions.module.css';

function Instructions() {
   const navigate = useNavigate();

   return (
      <div className={`${styles.wrapper}`}>
         <div className={`${styles.headingContainer}`}>
            <h1>Instructions</h1>
         </div>
         <div className={`${styles.contentContainer}`}>
            <div className={`${styles.contentBox}`}>
               {INSTRUCTIONS.map((instruction, index) => {
                  return <p>-&nbsp;{instruction}</p>;
               })}
            </div>
         </div>

         <div className={`${styles.buttonContainer}`}>
            <button className={styles.btn_s} onClick={() => navigate('/question')}>
               Play
            </button>
         </div>
      </div>
   );
}

export default Instructions;
