import styles from './GameOver.module.css';
import { useNavigate } from 'react-router-dom';
import confetti from 'canvas-confetti';
import { useEffect, useState } from 'react';
import ErrorPage from '../ErrorPage/ErrorPage';
import Loader from '../../components/Loader/Loader';

function GameOver() {
   const navigate = useNavigate();
   const [fetchError, setFetchError] = useState(false);
   const [loader, setLoader] = useState(false);

   useEffect(() => {
      // do this for 30 seconds
      var duration = 5 * 1000;
      var end = Date.now() + duration;

      var colors = ['#e23e27', '#ffffff'];

      (function frame() {
         confetti({
            particleCount: 2,
            angle: 60,
            spread: 100,
            origin: { x: 0 },
            colors: colors,
         });
         confetti({
            particleCount: 2,
            angle: 120,
            spread: 100,
            origin: { x: 1 },
            colors: colors,
         });

         if (Date.now() < end) {
            requestAnimationFrame(frame);
         }
      })();
   }, []);

   return (
      <>
         {loader ? (
            <Loader />
         ) : fetchError ? (
            <ErrorPage
               errorCode={500}
               errorMsg={"There's always time for a coffee break. We should be back by the time you finish your coffee."}
               buttonText={'Home'}
               buttonClickURL={'/'}
            />
         ) : (
            <div className={styles.wrapper}>
               <div className={styles.image_box}>
                  <img alt="img" src={`${process.env.PUBLIC_URL}/images/gameover/gameover.svg`} />
               </div>
               <div className={styles.text_box}>
                  <h1>Game Over :)</h1>
                  <p style={{ marginBottom: '2rem' }}>
                     Thanks for playing! <br /> Shorlisted teams will be contacted soon for Task 15
                  </p>
                  <a className={styles.link} href="https://drive.google.com/file/d/1ugRXwkFC6UFceKADps8OFkMe7G2zN-F8/view?usp=sharing" target="_blank">
                     Access Answer Key
                  </a>
               </div>
            </div>
         )}
      </>
   );
}

export default GameOver;
