import styles from './ErrorPage.module.css';
import Button from '../../components/Button/Button';
import { useNavigate } from 'react-router-dom';

function ErrorPage({ errorCode, errorMsg, buttonText, buttonClickURL }) {
   errorCode = '201';
   errorMsg = 'Game over for Sherlockk! Continue for Wattson';
   buttonText = 'Click here for Wattson Questions';
   buttonClickURL = '/questions';

   const navigate = useNavigate();

   const clickedButton = () => {
      navigate('/');
   };

   return (
      <div className={`${styles.wrapper}`}>
         <div className={`${styles.box} floater`}>
            <h1>{errorCode}&nbsp;:(</h1>
            <p>{errorMsg}</p>
            <Button text={buttonText} onClickMethod={() => clickedButton(buttonClickURL)} />
         </div>
      </div>
   );
}

export default ErrorPage;
