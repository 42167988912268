import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import FormField from '../../components/FormField/FormField';

import { PlayingContext } from '../../contexts/PlayingContext';

import KeyIcon from '../../assets/images/key.png';
import LockIcon from '../../assets/images/password.png'
import TeamIcon from '../../assets/images/teamname.png'
import TeamTypeIcon from '../../assets/images/teamtype.png'

import styles from './CreateTeam.module.css';

function CreateTeam() {
   const [buttonState, setButtonState] = useState({
      isDisabled: false,
      buttonText: 'Create Team',
   });

   const { handleCreateTeam } = useContext(PlayingContext);

   const [teamCredentials, setTeamCredentials] = useState({
      teamName: '',
      teamPassword: '',
      teamPasswordConfirm: '',
      teamType: '',
   });

   const changeTeamCredentials = (args) => {
      const prevState = teamCredentials;
      prevState[args.key] = args.value;
      setTeamCredentials({ ...prevState });
   };

   const clickedCreateTeam = async (e) => {
      e.preventDefault();

      setButtonState({
         isDisabled: true,
         buttonText: 'Creating...',
      });

      await handleCreateTeam(teamCredentials);

      setButtonState({
         isDisabled: false,
         buttonText: 'Create Team',
      });
   };

   return (
      <div className={styles.wrapper}>
         <div className={styles.leaderboard}>
            <form action="#" className={styles.form_div}>
               <h3 className={styles.form_head}>Create your team</h3>
               <div className={`${styles.formContainer}`}>
                  <FormField type={'text'} fieldIcon={TeamIcon} placeholder="Team Name" name="teamName" value={teamCredentials} setter={changeTeamCredentials} />
                  <FormField
                     type={'password'}
                     fieldIcon={LockIcon}
                     placeholder="Team Password"
                     name="teamPassword"
                     value={teamCredentials}
                     setter={changeTeamCredentials}
                  />
                  <FormField
                     type={'password'}
                     fieldIcon={KeyIcon}
                     placeholder="Confirm Password"
                     name="teamPasswordConfirm"
                     value={teamCredentials}
                     setter={changeTeamCredentials}
                  />
                  <FormField
                     type={'dropdown'}
                     fieldIcon={TeamTypeIcon}
                     placeholder="Team Type"
                     name="teamType"
                     value={teamCredentials}
                     setter={changeTeamCredentials}
                     dropdownValues={['Duo', 'Solo']}
                  />
               </div>

               <button disabled={buttonState.isDisabled} onClick={clickedCreateTeam} className={styles.btn_o}>
                  <span>&nbsp;</span>
                  {buttonState.buttonText}
               </button>
            </form>
         </div>
      </div>
   );
}

export default CreateTeam;
