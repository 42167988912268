import React from "react";
import styles from "./SimpleLoader.module.css";

function SimpleLoader({ text = "Loading..." }) {
  return (
    <div className={`${styles.container}`}>
      <section className={`${styles.sec}`}>
        <div className={`${styles.loader} ${styles.loader_1}`}>
          <div className={`${styles.loader_outter}`}></div>
          <div className={`${styles.loader_inner}`}></div>
        </div>
        <p>{text}</p>
      </section>
    </div>
  );
}

export default SimpleLoader;
