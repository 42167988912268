import React, { useRef } from 'react';

import { FaMobile, FaMailBulk } from 'react-icons/fa';

import { CONTACT_INFO, TECHIES_INFO } from '../../data/ContactInfo.js';
import styles from './Contact.module.css';

const Contact = () => {
   return (
      <div className={styles.align}>
         {/* <p>{!imagesLoaded ? <SimpleLoader></SimpleLoader> : ""}</p> */}
         <div className={styles.object}>
            <h1>CONTACT</h1>
         </div>
         <div className={styles.flip_card}>
            <div className={styles.flip_card_inner}>
               <div className={styles.flip_card_front}>
                  <h1>CONTACT</h1>
               </div>
               <div className={styles.flip_card_back}>
                  <h2>For any queries</h2>

                  {CONTACT_INFO.map((info, idx) => (
                     <li key={idx}>
                        <FaMobile /> {info}
                     </li>
                  ))}

                  <h2>For technical issues</h2>

                  {TECHIES_INFO.map((info, idx) => (
                     <li key={idx}>
                        <FaMobile /> {info}
                     </li>
                  ))}
               </div>
            </div>
         </div>
      </div>
   );
};

export default Contact;
